import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { collection, addDoc } from "firebase/firestore";
import {
  ContactContainer,
  ContactContent,
  ContactH3,
  ContactP,
  Form,
  FormLabel,
  FormInput,
  FormButton,
} from "./ContactElements";
import { firestore } from "@/config/firebase";

const Contact = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    addDoc(collection(firestore, "contacts"), {
      email: email,
    });
    setEmail("");
    toast.success("Thanks for subscribing!");
  };

  return (
    <ContactContainer id="contact">
      <ContactContent>
        <ContactH3>Want to get in touch?</ContactH3>
        <ContactP>Submit your email below.</ContactP>
        <Form id="Contact" onSubmit={handleSubmit}>
          <FormLabel htmlFor="email">Email</FormLabel>
          <FormInput
            type="email"
            name="email"
            id="email"
            value={email}
            onChange={({ target }) => setEmail(target.value)}
            placeholder="coms@gmail.com"
            required
          />
          <FormButton type="submit" id="submit">
            Submit
          </FormButton>
          <Toaster
            toastOptions={{
              className: "toaster",
            }}
          />
        </Form>
      </ContactContent>
    </ContactContainer>
  );
};

export default Contact;
