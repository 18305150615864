import styled from "styled-components";

export const ContactContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 30px;
  margin: 0 auto;
  height: 110px;
  position: relative;
  background: #4b5381;
  border: solid 1px #424971;
  z-index: 1;

  @media screen and (max-width: 768px) {
    height: 200px;
    padding: 20px;
  }
`;

export const ContactContent = styled.div`
  width: 630px;
  z-index: 3;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: left;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    width: 410px;
  }

  @media screen and (max-width: 480px) {
    width: 305px;
  }
`;

export const ContactH3 = styled.h3`
  color: #fff;
  font-size: 1.625rem;
  text-align: left;
  margin-top: -10px;
  margin-bottom: 5px;

  @media screen and (max-width: 768px) {
    font-size: 1.25rem;
  }
`;

export const ContactP = styled.p`
  color: #fff;
  margin-top: 4px;
  margin-bottom: 17px;
  font-size: 1.125rem;
  font-weight: 200;

  @media screen and (max-width: 768px) {
    font-size: 1rem;
    margin-bottom: 18px;
  }
`;

export const Form = styled.form`
  height: auto;
  width: 100%;
  z-index: 1;
  display: inline;
  margin: 0 auto;
`;

export const FormLabel = styled.label`
  display: none;
`;

export const FormInput = styled.input`
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  padding: 12px 40px 12px 12px;
  margin-top: 2px;
  border: none;
  border-radius: 4px;
  flex-grow: 2;
  border: solid 1px #424971;
`;

export const FormButton = styled.button`
  position: absolute;
  right: 7px;
  bottom: 7px;
  line-height: 1;
  border-radius: 5px;
  cursor: pointer;
  padding: 7px;
  font-size: 0.875rem;
  font-weight: 600;
  background-color: #424971;
  color: #fff;
  border: none;

  &:hover {
    background-color: #4b5381;
    box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0.24);
  }
`;
